import { env } from '$env/dynamic/public';

export const allowUrls = [/https?:\/\/platform\.denoutdoors\.com/];

export const dsn =
	'https://069f2f8489d54e33866d0f2592118102@o4505000472543232.ingest.sentry.io/4505515766710273';

export const enabled = (env.PUBLIC_ENV ?? 'dev') !== 'dev';

export const environment = env.PUBLIC_ENV;

export const ignoreErrors = [
	'*could not be loaded. Make sure that the file is accessible from your webserver.', // https://den-outdoors.sentry.io/issues/5019512771
	'Aborted(). Build with -sASSERTIONS for more info.', // https://den-outdoors.sentry.io/issues/5056437338
	'could not be loaded. Make sure that the file is accessible from your webserver.', // https://den-outdoors.sentry.io/issues/5019512771
	'Failed to fetch', // https://den-outdoors.sentry.io/issues/5061981625
	'Failed to fetch*', // https://den-outdoors.sentry.io/issues/5061981625
	'i.user is undefined', // https://den-outdoors.sentry.io/issues/4977411195
	'Importing a module script failed.', // https://den-outdoors.sentry.io/issues/4513076962
	'Load failed', // https://den-outdoors.sentry.io/issues/5019509866 https://sentry.io/answers/load-failed-javascript/
	'NetworkError when attempting to fetch resource.', // https://den-outdoors.sentry.io/issues/4973150436
	'Non-Error promise rejection captured with value*', // https://docs.sentry.io/platforms/javascript/guides/sveltekit/troubleshooting/#events-with-non-error-exception
	'Object captured as promise rejection with keys', // https://den-outdoors.sentry.io/issues/5048434344
	'Object captured as promise rejection with keys*', // https://den-outdoors.sentry.io/issues/5048434344
	'The encoding operation (either encoded or decoding) failed.', // https://den-outdoors.sentry.io/issues/5050293283
	'Unable to preload CSS for *', // https://den-outdoors.sentry.io/issues/5062673123
	`*Cannot find translations for "en". Please retry or add them manually to PSPDFKit.I18n.messages["en"]`, // https://den-outdoors.sentry.io/issues/5131925412
	/Object Not Found Matching Id:/, // https://den-outdoors.sentry.io/issues/5062569552
];

export const denyUrls = [
	// Chrome extensions
	/extensions\//i,
	/^chrome:\/\//i,
	/^chrome-extension:\/\//i,
];

export const tracesSampleRate = 0.5;
